var exports = {};

/*jshint bitwise: false */
var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
/**
 * Base64-encodes a string of text.
 *
 * @param {string} text The text to encode.
 * @return {string} The base64-encoded string.
 */

function base64Encode(text) {
  if (/([^\u0000-\u00ff])/.test(text)) {
    throw new Error("Can't base64 encode non-ASCII characters.");
  }

  var i = 0,
      cur,
      prev,
      byteNum,
      result = [];

  while (i < text.length) {
    cur = text.charCodeAt(i);
    byteNum = i % 3;

    switch (byteNum) {
      case 0:
        //first byte
        result.push(digits.charAt(cur >> 2));
        break;

      case 1:
        //second byte
        result.push(digits.charAt((prev & 3) << 4 | cur >> 4));
        break;

      case 2:
        //third byte
        result.push(digits.charAt((prev & 15) << 2 | cur >> 6));
        result.push(digits.charAt(cur & 63));
        break;
    }

    prev = cur;
    i += 1;
  }

  if (byteNum === 0) {
    result.push(digits.charAt((prev & 3) << 4));
    result.push("==");
  } else if (byteNum === 1) {
    result.push(digits.charAt((prev & 15) << 2));
    result.push("=");
  }

  return result.join("");
}
/**
 * Base64-decodes a string of text.
 *
 * @param {string} text The text to decode.
 * @return {string} The base64-decoded string.
 */


function base64Decode(text) {
  //ignore white space
  text = text.replace(/\s/g, ""); //first check for any unexpected input

  if (!/^[a-z0-9\+\/\s]+\={0,2}$/i.test(text) || text.length % 4 > 0) {
    throw new Error("Not a base64-encoded string.");
  } //local variables


  var cur,
      prev,
      digitNum,
      i = 0,
      result = []; //remove any equals signs

  text = text.replace(/\=/g, ""); //loop over each character

  while (i < text.length) {
    cur = digits.indexOf(text.charAt(i));
    digitNum = i % 4;

    switch (digitNum) {
      //case 0: first digit - do nothing, not enough info to work with
      case 1:
        //second digit
        result.push(String.fromCharCode(prev << 2 | cur >> 4));
        break;

      case 2:
        //third digit
        result.push(String.fromCharCode((prev & 15) << 4 | cur >> 2));
        break;

      case 3:
        //fourth digit
        result.push(String.fromCharCode((prev & 3) << 6 | cur));
        break;
    }

    prev = cur;
    i += 1;
  } //return a string


  return result.join("");
}

exports = {
  encode: base64Encode,
  decode: base64Decode
};
export default exports;
export const encode = exports.encode,
      decode = exports.decode;